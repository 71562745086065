import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Profile } from '@wizbii-drive/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SponsorWebservice {
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      this.baseUrl = `/_api/permii-api`;
    } else {
      this.baseUrl = `http://permii-api`;
    }
  }

  getProfileBySponsoringCode(sponsoringCode: string): Observable<Profile> {
    return this.http.get<Profile>(`${this.baseUrl}/v1/profile/sponsorship-code/${sponsoringCode}`);
  }
}
