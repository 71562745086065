import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeInOut } from '@commons/animations/fade-in-out';
import { loaderIndicator } from '@commons/animations/loader';
import { slideDown } from '@commons/animations/slide';
import {
  ContextAccountComponent,
  ContextDriveHome2Component,
  ContextDriveHomeComponent,
  ContextDriveInfluencerComponent,
  ContextDriveSuggestionsComponent,
  ContextJobsHomeComponent,
  ContextJobsOfferComponent,
  ContextMoneyHomeComponent,
  ContextMoneyNexityComponent,
} from '@commons/context';
import { ContextDriveCollectivityComponent } from '@commons/context/context-drive-collectivity/context-drive-collectivity.component';
import { ContextDriveDrivingSchoolComponent } from '@commons/context/context-drive-driving-school/context-drive-driving-school.component';
import { ContextDriveSponsorshipComponent } from '@commons/context/context-drive-sponsorship/context-drive-sponsorship.component';
import { ContextMoneyCollectivityComponent } from '@commons/context/context-money-collectivity/context-money-collectivity.component';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { ServicesState } from '@stores/services/services.state';
import { SessionState } from '@stores/session/session.state';
import { PaymentWebservice } from '@wizbii-drive/webservices';
import { CountryService } from '@wizbii/utils/models';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

const SignUpContext: Record<string, Type<any>> = {
  'wizbii-account': ContextAccountComponent,
  account: ContextAccountComponent,
  'wizbii-jobs-home': ContextJobsHomeComponent,
  jobs: ContextJobsHomeComponent,
  'wizbii-drive-home': ContextDriveHomeComponent,
  'wizbii-drive-home-2': ContextDriveHome2Component,
  'wizbii-drive-sponsorship': ContextDriveSponsorshipComponent,
  'wizbii-drive-collectivity-pau': ContextDriveCollectivityComponent,
  'wizbii-drive-driving-school': ContextDriveDrivingSchoolComponent,
  'permii-app': ContextDriveHomeComponent,
  'wizbii-drive-suggestions': ContextDriveSuggestionsComponent,
  'fibii-home': ContextMoneyHomeComponent,
  fibii: ContextMoneyHomeComponent,
  'money-intl': ContextMoneyHomeComponent,
  'fibii-collectivity-pau': ContextMoneyCollectivityComponent,
  'wizbii-drive-influencer': ContextDriveInfluencerComponent,
  'fibii-nexity': ContextMoneyNexityComponent,
  fibii_nexity: ContextMoneyNexityComponent,
  'wizbii-jobs-offer': ContextJobsOfferComponent,
  default: ContextAccountComponent,
};

@UntilDestroy()
@Component({
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [fadeInOut(), loaderIndicator(), slideDown()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent implements OnInit, OnDestroy {
  @ViewChild('contextComponent', { static: true, read: ViewContainerRef }) contextRef: ViewContainerRef;

  wizbiiDriveInfluencer = 'wizbii-drive-influencer';

  @Select(ServicesState.services)
  servicesList$: Observable<CountryService[]>;

  @Select(SessionState.currentContext)
  currentContext$: Observable<string>;

  @Select(SessionState.currentAppId)
  currentAppId$: Observable<string>;

  @Select(SessionState.contextCoupon)
  couponStr$: Observable<string>;

  isCouponValid$ = new BehaviorSubject<boolean>(false);
  sponsoringCode$ = new BehaviorSubject<string>(undefined);
  extraContent$ = new BehaviorSubject<string>(undefined);

  FeaturesRoutingEnum = FeaturesRoutingEnum;

  showSignupEmail$ = new BehaviorSubject<boolean>(false);

  get isMobile$(): Observable<boolean> {
    return this.breakpointsService.isMobile$;
  }

  get isMobileAndTabletPortrait$(): Observable<boolean> {
    return this.breakpointsService.isMobileAndTabletPortrait$;
  }

  constructor(
    private readonly breakpointsService: BreakpointsService,
    private readonly paymentWebservice: PaymentWebservice,
    private readonly store: Store,
    private readonly route: ActivatedRoute
  ) {
    combineLatest([this.couponStr$.pipe(distinctUntilChanged()), this.currentContext$.pipe(distinctUntilChanged())])
      .pipe(
        untilDestroyed(this),
        switchMap(([couponStr, context]) =>
          context === this.wizbiiDriveInfluencer ? this.paymentWebservice.getCouponPublic(couponStr) : of(null)
        )
      )
      .subscribe((coupon) => (coupon ? this.isCouponValid$.next(true) : this.isCouponValid$.next(false)));
  }

  // tslint:disable-next-line: cognitive-complexity
  ngOnInit(): void {
    this.initContext();

    this.store
      .select(RouterState.state)
      .pipe(
        map((state: any) => state.data),
        map((data) => data.signupMethod),
        untilDestroyed(this)
      )
      .subscribe((signupMethod) => {
        this.showSignupEmail$.next(!signupMethod || signupMethod === 'email');
      });

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((queryParamMap) => {
      this.sponsoringCode$.next(
        queryParamMap.has('redirect') && queryParamMap.get('redirect').includes('sponsoringCode')
          ? queryParamMap.get('redirect').split('sponsoringCode=')[1]
          : undefined
      );
    });
  }

  // tslint:disable-next-line: cognitive-complexity
  private initContext(): void {
    combineLatest([
      combineLatest([
        this.currentContext$.pipe(distinctUntilChanged()),
        this.currentAppId$.pipe(distinctUntilChanged()),
        this.isCouponValid$.pipe(distinctUntilChanged()),
        this.sponsoringCode$.pipe(distinctUntilChanged()),
      ]).pipe(
        distinctUntilChanged(),
        map(([context, appId, isCouponValid, sponsoringCode]) => {
          if (context === this.wizbiiDriveInfluencer && !isCouponValid) {
            return ['wizbii-drive-home', appId];
          }

          if (sponsoringCode) {
            this.extraContent$.next(
              "*Remise de 25% remise immédiatement pour tout achat d'une Formule Illimité sur le site https://drive.wizbii.com/, sous condition d'entrer le code promotionnel dans le champ prévu à cet effet sur la page de paiement. Ce code promotionnel vous sera envoyé par email une fois votre compte créé sur https://drive.wizbiicom/ et sera valable 30 jours à compter de sa date de réception. Non cuulable avec toute offre promotionnelle en cours."
            );
            return ['wizbii-drive-sponsorship', appId];
          }

          return [context, appId];
        }),
        map(([context, appId]) =>
          context && context !== 'default' ? SignUpContext[context] : appId ? SignUpContext[appId] : null
        ),
        map((component) => (component ? component : SignUpContext['default'])),
        untilDestroyed(this)
      ),
      this.breakpointsService.isMobileAndTabletPortrait$,
    ]).subscribe(([component, isMobileAndTabletPortrait]) => {
      if (!isMobileAndTabletPortrait) {
        const contextRef = this.contextRef;

        if (this.contextRef) {
          this.contextRef.clear();
        }

        const ref = contextRef.createComponent<any>(component);
        ref.changeDetectorRef.detectChanges();
      } else {
        this.contextRef.clear();
      }
    });
  }

  trackByService(_: number, service: CountryService): string {
    return service.id;
  }

  ngOnDestroy(): void {
    this.showSignupEmail$.complete();
    this.isCouponValid$.complete();
  }
}
