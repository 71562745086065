import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, PLATFORM_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { IsAlreadyConnectedGuard } from '@features/guards/is-already-connected/is-already-connected.guard';
import { LoginTokenGuard } from '@features/guards/login-token/login-token.guard';
import { MustBeConnectedGuard } from '@features/guards/must-be-connected/must-be-connected.guard';
import {
  DashboardLayoutComponent,
  DashboardLayoutModule,
} from '@features/layouts/dashboard-layout/dashboard-layout.component';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CONSENT_API_CONFIG,
  CONTACT_API_CONFIG,
  CONTENT_API_CONFIG,
  CityWebservice,
  ConsentWebservice,
  ContactWebservice,
  ContentLegalWebservice,
  JOB_API_CONFIG,
  JobWebservice,
  PLACE_API_CONFIG,
} from '@wizbii-utils/angular/webservices';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginTokenGuard],
    children: [
      {
        path: FeaturesRoutingEnum.SignIn,
        loadChildren: () => import('./sign-in/sign-in.module').then((m) => m.SignInModule),
      },
      {
        path: FeaturesRoutingEnum.SignUp,
        loadChildren: () => import('./sign-up/sign-up.module').then((m) => m.SignUpModule),
      },
      {
        path: '',
        canActivate: [IsAlreadyConnectedGuard],
        children: [
          {
            path: '',
            loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
          },
          {
            path: FeaturesRoutingEnum.LostPassword,
            loadChildren: () => import('./lost-password/lost-password.module').then((m) => m.LostPasswordModule),
          },
        ],
      },
      {
        path: FeaturesRoutingEnum.ResetPassword,
        loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        path: FeaturesRoutingEnum.Unsubscribe,
        loadChildren: () => import('./unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
      },
      {
        path: '',
        canActivate: [MustBeConnectedGuard],
        component: DashboardLayoutComponent,
        children: [
          {
            path: FeaturesRoutingEnum.Dashboard,
            loadChildren: () => import('./dashboard-home/dashboard-home.module').then((m) => m.DashboardHomeModule),
          },
          {
            path: FeaturesRoutingEnum.Profile,
            loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
          },
          {
            path: FeaturesRoutingEnum.Privacy,
            loadChildren: () => import('./privacy/privacy.module').then((m) => m.PrivacyModule),
          },
          {
            path: FeaturesRoutingEnum.Security,
            loadChildren: () => import('./security/security.module').then((m) => m.SecurityModule),
          },
          {
            path: FeaturesRoutingEnum.Notifications,
            loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule),
          },
          {
            path: FeaturesRoutingEnum.Logout,
            loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule),
          },
        ],
      },
    ],
  },
];
@NgModule({
  imports: [
    CommonModule,
    DashboardLayoutModule,
    HttpClientModule,
    RouterModule.forChild([
      ...routes,
      {
        path: ':lang',
        children: [...routes],
      },
    ]),
  ],
  providers: [
    IsAlreadyConnectedGuard,
    MustBeConnectedGuard,
    LoginTokenGuard,
    {
      provide: AUTHENTICATION_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/auth-api' : 'http://auth-api',
        locale: environment.locale,
        cookieDomains: environment.domainsByLocale,
        platform: environment.platform,
        defaultCookieDomain: environment.platform === 'local' ? 'localhost' : undefined,
      }),
      deps: [PLATFORM_ID],
    },
    AuthenticationWebservice,

    JobWebservice,
    {
      provide: JOB_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/job-api' : 'http://job-api',
        locale: environment.locale,
      }),
      deps: [PLATFORM_ID],
    },
    CityWebservice,
    {
      provide: PLACE_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/place-api' : 'http://place-api',
        locale: environment.locale,
      }),
      deps: [PLATFORM_ID],
    },
    ConsentWebservice,
    {
      provide: CONSENT_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/consent-api' : 'http://consent-api',
        locale: environment.locale,
      }),
      deps: [PLATFORM_ID],
    },
    ContentLegalWebservice,
    {
      provide: CONTENT_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/content-api' : 'http://content-api',
        locale: environment.locale,
      }),
      deps: [PLATFORM_ID],
    },
    ContactWebservice,
    {
      provide: CONTACT_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/contact-api' : 'http://contact-api',
        locale: environment.locale,
      }),
      deps: [PLATFORM_ID],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeaturesModule {}
