import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { Select } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { WzbCompanyLogoComponent } from '@wizbii-components/angular-ui';
import { JobWebservice } from '@wizbii-utils/angular/webservices';
import { Job } from '@wizbii/utils/models';
import { Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-context-jobs-offer',
  templateUrl: './context-jobs-offer.component.html',
  styleUrls: ['./context-jobs-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextJobsOfferComponent implements OnInit {
  job$: Observable<Job>;

  @Select(SessionState.contextJobId)
  jobId$: Observable<string>;

  get isMobileAndTabletPortrait$(): Observable<boolean> {
    return this.breakpointsService.isMobileAndTabletPortrait$;
  }

  constructor(
    private readonly jobWebservice: JobWebservice,
    private readonly breakpointsService: BreakpointsService
  ) {}

  ngOnInit(): void {
    this.job$ = this.jobId$.pipe(
      distinctUntilChanged(),
      switchMap((jobId) => this.jobWebservice.get(jobId)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  trackBySkill(_: number, skill: string): string {
    return skill;
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, WzbCompanyLogoComponent],
  declarations: [ContextJobsOfferComponent],
  exports: [ContextJobsOfferComponent],
})
export class ContextJobsOfferModule {}
