import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { SponsorWebservice } from '@webservices/sponsor/sponsor.webservice';
import { Profile } from '@wizbii-drive/models';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-context-drive-sponsorship',
  templateUrl: './context-drive-sponsorship.component.html',
  styleUrls: ['./context-drive-sponsorship.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextDriveSponsorshipComponent {
  get isMobileAndTabletPortrait$(): Observable<boolean> {
    return this.#breakpointsService.isMobileAndTabletPortrait$;
  }

  sponsorProfile$: Observable<Profile>;

  #sponsorWebservice = inject(SponsorWebservice);
  #breakpointsService = inject(BreakpointsService);
  #activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.sponsorProfile$ = this.#activatedRoute.queryParamMap.pipe(
      take(1),
      map((queryParamMap) => queryParamMap.get('redirect').split('sponsoringCode=')[1].split('&')[0]),
      switchMap((sponsoringCode) => this.#sponsorWebservice.getProfileBySponsoringCode(sponsoringCode))
    );
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [ContextDriveSponsorshipComponent],
  exports: [ContextDriveSponsorshipComponent],
})
export class ContextDriveSponsorshipModule {}
