<section class="context-money-home">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <div class="quote">
      <div class="quote__content">
        <span class="highlighted--yellow quote__content__name" i18n>Amélie</span>
        <p class="quote__content__situation" i18n>21 ans Étudiante à ENSE3 Grenoble</p>
        <p class="quote__content__suggestions" i18n>
          CROUS&nbsp;:&nbsp;209&nbsp;€&nbsp;/&nbsp;mois&nbsp;&nbsp;&nbsp;&nbsp;APL&nbsp;:&nbsp;97&nbsp;€&nbsp;/&nbsp;mois
        </p>
        <p class="quote__content__total" i18n>
          <span>Total&nbsp;:&nbsp;3&nbsp;673&nbsp;€&nbsp;/&nbsp;an</span>&nbsp;pour l’aider à financer ses études
        </p>
      </div>
    </div>
    <p class="card card--1" i18n>
      Calculez gratuitement vos
      <span class="highlighted--yellow quote__name">aides financières&nbsp;!</span>
    </p>
    <p class="card card--2" i18n>
      Finies les galères, découvrez le montant d'aides par an via
      <span class="highlighted--yellow quote__name">le simulateur WIZBII Money&nbsp;!</span>
    </p>
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-money-home__pop-up" i18n>
    Calculez gratuitement vos
    <span class="highlighted--yellow quote__name">aides financières&nbsp;!</span>
  </p>
</ng-template>
